import moment from 'moment';

/* eslint-disable prefer-template */
export const trim = (value) => (value ? value.trim() : value);

export const trimNumber = (value) => (value ? Number(value.toString().trim()) : value);

export const trimDecimals = (value) => (value ? parseInt(value.trim(), 10) : value);

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const convertToCurrency = (price = NaN, priceCurrency = 'usd') => price.toLocaleString('en', {
  style: 'currency',
  currency: priceCurrency,
});

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // eslint-disable-next-line no-restricted-properties
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const formatMetrics = (number, locale, decimals = 2, compactDecimals = 1, dataType, currencyType, params) => {
  // “decimals“ controls the decimals of numbers > -1000 && < 1000
  // “compactDecimals“ controls the decimals of numbers  < -1000 && > 1000
  // "compactDecimals" === null then the number should not be compacted (e.g. 12345 = 12,345)
  if (number === null || number === undefined) return '';
  if (dataType === 'string') return number;

  const formatterOptions = {
    notation: 'compact',
    maximumFractionDigits: decimals,
    trailingZeroDisplay: params?.trailingZeroDisplay || 'auto',
  };
  if (compactDecimals === null) {
    formatterOptions.notation = 'standard';
    formatterOptions.minimumFractionDigits = decimals;
  }
  if ((number <= -1000 || number >= 1000) && compactDecimals) formatterOptions.maximumFractionDigits = compactDecimals;

  if (dataType === 'currency') {
    formatterOptions.currency = currencyType || 'USD';
    formatterOptions.style = 'currency';
  } else if (dataType === 'percentage') {
    formatterOptions.style = 'percent';
  }
  return Intl.NumberFormat([locale, 'en'], formatterOptions).format(number);
};

export const formatDate = (date) => moment(date).format('L');

export const formatChartDate = (metricDate, period = '', type) => {
  if ([0, 'auto'].includes(metricDate)) return '';
  const localeShortFormatString = () => {
    const formatL = moment.localeData().longDateFormat('L');
    const formatYearlessL = formatL.replace(/Y/g, '').replace(/^\W|\W$|\W\W/, '');
    return formatYearlessL;
  };
  if (period === 'week' && type === 'short') return moment.utc(metricDate).format(localeShortFormatString());
  if (period === 'week') return moment.utc(metricDate).format('L');
  if (period === 'month') return moment.utc(metricDate).format('MMM');
  if (type === 'short') return moment.utc(metricDate).format(localeShortFormatString());
  return moment.utc(metricDate).format('L');
};

export const formatDataGridDate = (metricDate, period = '') => {
  if ([0, 'auto'].includes(metricDate)) return '';
  if (period === 'week') return moment.utc(metricDate).format('L');
  if (period === 'month') return moment.utc(metricDate).format('MM/YYYY');
  return moment.utc(metricDate).format('L');
};

export const formatDurationFromSeconds = (seconds, precision = 3) => moment.duration(seconds, 'seconds')
  .format('Y[y] M[m] w[w] d[d] h[h] m[m] s[s]', {
    trim: 'both mid',
    trunc: true,
    useSignificantDigits: true,
    precision,
  });

export const formatDebugMetrics = (number, locale, params) => {
  if (number === null || number === undefined) return '';
  if (params?.dataType === 'string') return number;

  const formatterOptions = {
    notation: 'standard',
    maximumFractionDigits: params?.maximumFractionDigits || 2,
    minimumFractionDigits: params?.minimumFractionDigits || 2,
    trailingZeroDisplay: params?.trailingZeroDisplay || 'auto',
  };

  if (params?.dataType === 'currency') {
    formatterOptions.currency = params?.currency || 'USD';
    formatterOptions.style = 'currency';
  } else if (params?.dataType === 'percentage') {
    formatterOptions.style = 'percent';
  }
  return Intl.NumberFormat([locale, 'en'], formatterOptions).format(number);
};

export const formatData = (value, type = 'text', params = {}) => {
  if (value === null || value === undefined) return '--';
  if (type === 'duration') return formatDurationFromSeconds(value, params?.precision);
  if (type === 'date') return formatDate(value);
  if (type === 'number') return formatMetrics(value, 'en-us', params?.decimalPlaces, params?.compactDecimals);
  if (type === 'wholeNumber') return formatMetrics(value, 'en-us', 0, 0);
  if (type === 'currency') {
    return formatMetrics(
      value,
      'en-us',
      params?.decimalPlaces,
      params?.compactDecimals,
      'currency',
      params?.currency,
      params,
    );
  }
  if (type === 'percentage') return formatMetrics(value, 'en-us', params?.decimalPlaces, params?.compactDecimals, 'percentage');
  if (type === 'debug') return formatDebugMetrics(value, 'en-us', params);
  return value;
};
